import { safeStringify } from "./safe-stringify";

export function getErrorMessage(error: any): string {
  if (error != undefined) {
    if (error.graphQLErrors != undefined) {
      for (const graphQLError of error.graphQLErrors) {
        if (
          graphQLError.extensions &&
          graphQLError.extensions.code === "BAD_USER_INPUT"
        ) {
          return graphQLError.message;
        }
      }
    }

    if (error.message != undefined) {
      if (error.message !== "") {
        return error.message;
      } else {
        if (error.ref && error.type) {
          return (error.ref.id ?? error.ref.id) + "." + error.type;
        }
      }
    }
  }

  return safeStringify(error);
}

export function getFriendlyErrorMessage(e: any): string {
  // validation error
  if (e.ref != undefined) {
    return e.message;
  }
  const unfriendly = getErrorMessage(e);

  switch (unfriendly) {
    case "Failed to fetch":
      return "Could not connect to the backend server. Please check your internet connection and try again.";
    default:
      return unfriendly;
  }
}
