import { isEmpty } from "lodash";
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  ReactNode,
  useCallback,
  useState,
} from "react";

import { tw } from "../../tailwindcss-classnames";
import { B2 } from "../type/Typography";

import { Errors } from "./errors";

import { c } from "lib/c";

export interface WithLabelProps {
  name: string;
  required?: boolean;
  disabled?: boolean;
  label: string;
  explainText?: string | ReactNode;
  error?: any;
  inputRef?: any;
  wrapperClass?: string;
  labelPointerEventsNone?: boolean;
  forceActive?: boolean;
  children: (arguments_: {
    handleChange: ChangeEventHandler;
    active: boolean;
    name: string;
    required?: boolean;
    inputProps: any;
  }) => ReactNode;
}

export const WithLabel: FC<WithLabelProps> = ({
  required,
  error,
  disabled,
  children,
  name,
  label,
  explainText,
  forceActive,
  wrapperClass,
  labelPointerEventsNone,
  ...inputProps
}) => {
  const [hasFocus, setFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const active = forceActive || hasFocus || hasValue;

  const onFocusHandler = useCallback<FocusEventHandler<HTMLDivElement>>(() => {
    setFocused(true);
  }, [setFocused]);

  const onBlurHandler = useCallback<FocusEventHandler<HTMLDivElement>>(() => {
    setFocused(false);
  }, [setFocused]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setHasValue(!!e.target.value);
    },
    [setHasValue]
  );
  const hasError = !isEmpty(error);

  return (
    <>
      <div
        className={c(
          tw(
            "relative",
            "border",
            {
              "pointer-events-none": disabled,
              "bg-transparent": !disabled,
              "bg-unassumingGray": disabled,
              ["hover:shadow-outline"]: !disabled && !hasFocus,
              ["border-unassumingGray" as any]: disabled,
              ["border-gold" as any]: !disabled && hasFocus,
              ["shadow-outlineGold"]: !disabled && hasFocus,
              ["border-ink" as any]: !disabled && !hasFocus,
              ["border-mistakeRed" as any]: hasError,
              ["hover:shadow-outlineRed"]: hasError,
            },
            "rounded-8"
          ),
          wrapperClass
        )}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        ref={(ref) => {
          // hacky shit but it works
          if ((ref?.querySelector("input,select") as any)?.value) {
            setHasValue(true);
          } else {
            setHasValue(false);
          }
        }}
      >
        {children({ handleChange, active, name, required, inputProps })}
        <label
          className={tw({
            "leading-none": true,
            absolute: true,
            "left-0": true,
            "top-0": true,
            flex: true,

            "px-16": true,
            "pt-20": !active,
            "pt-8": active,

            "transition-all": true,
            "duration-200": true,
            "ease-in-out": true,

            "items-center": true,

            "text-disabledTextGray": disabled,
            "text-gold": !hasError && !disabled && hasFocus,
            "text-ink": !hasError && !disabled && !hasFocus,
            "text-mistakeRed": hasError,

            "text-xs": active,
            "text-base": !active,
            "pointer-events-none": labelPointerEventsNone,
          })}
          id={[name, "label"].join("-")}
          htmlFor={[name, "input"].join("-")}
        >
          {label}
        </label>
      </div>
      <Errors errors={error} className={tw("py-2", "px-16", "text-xs")} />
      {explainText && (
        <B2 className={tw("py-4", "leading-loose")}>{explainText}</B2>
      )}
    </>
  );
};
