/* eslint-disable unicorn/no-null */
import * as Sentry from "@sentry/node";
import React, { FC, useEffect } from "react";

import { tw } from "../../tailwindcss-classnames";

import { c } from "lib/c";
import {
  getErrorMessage,
  getFriendlyErrorMessage,
} from "lib/util/get-error-message";

export const Errors: FC<{ errors: any; className?: string }> = ({
  errors,
  className,
}) => {
  useEffect(() => {
    if (Array.isArray(errors)) {
      for (const error of errors.filter((it) => !!it))
        Sentry.captureException(error);
    } else if (errors) {
      Sentry.captureException(errors);
    }
  }, [errors]);

  if (!errors) {
    return null;
  }

  if (Array.isArray(errors)) {
    if (errors.filter((it) => !!it).length === 0) {
      return null;
    }
    return (
      <ul className={c(tw("text-mistakeRed"), className)}>
        {errors
          .filter((it) => !!it)
          .map((error) => (
            <li key={getErrorMessage(error)}>
              {getFriendlyErrorMessage(error)}
            </li>
          ))}
      </ul>
    );
  } else {
    if (Object.values(errors).length === 0) {
      return null;
    }

    return (
      <div className={c(tw("text-mistakeRed"), className)}>
        {getFriendlyErrorMessage(errors)}
      </div>
    );
  }
};
